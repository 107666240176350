import { Link, graphql } from 'gatsby'

import Layout from '../../components/Layout'
import React from 'react'

const BulmaThemeTest = () => (
  <div className="column">
    <section className="section" id="typography">
      <h1 className="title"> Typography </h1>
      <hr />
      <div className="columns">
        <div className="column">
          <h1 className="title is-1"> Title 1 </h1>
          <h2 className="title is-2"> Title 2 </h2>
          <h3 className="title is-3"> Title 3 </h3>
          <h4 className="title is-4"> Title 4 </h4>
          <h5 className="title is-5"> Title 5 </h5>
          <h6 className="title is-6"> Title 6 </h6>
        </div>
        <div className="column">
          <h1 className="subtitle is-1"> Subtitle 1 </h1>
          <h2 className="subtitle is-2"> Subtitle 2 </h2>
          <h3 className="subtitle is-3"> Subtitle 3 </h3>
          <h4 className="subtitle is-4"> Subtitle 4 </h4>
          <h5 className="subtitle is-5"> Subtitle 5 </h5>
          <h6 className="subtitle is-6"> Subtitle 6 </h6>
        </div>
        <div className="column">
          <h2 className="is-branded title is-1"> Simian 1 </h2>
          <h3 className="is-branded title is-2"> Simian 2 </h3>
          <h1 className="is-branded title is-3"> Simian 3 </h1>
          <h4 className="is-branded title is-4"> Simian 4 </h4>
          <h5 className="is-branded title is-5"> Simian 5 </h5>
          <h6 className="is-branded title is-6"> Simian 6 </h6>
        </div>
      </div>
      <hr />
      <div className="columns">
        <div className="column">
          <p className="title is-2 is-branded">My Blog Post</p>
          <p className="subtitle is-4"> Subtitle 4 </p>
        </div>
        <div className="column">
          <p className="title is-3"> Title 3 </p>
          <p className="subtitle is-5"> Subtitle 5 </p>
        </div>
        <div className="column">
          <h1 className="title"> Title </h1>
          <h2 className="subtitle"> Subtitle </h2>
        </div>
        <div className="column">
          <p className="title is-1"> Title 1 </p>
          <p className="subtitle is-3"> Subtitle 3 </p>
        </div>
      </div>
    </section>

    <section className="section" id="box">
      <h1 className="title"> Box </h1>
      <hr />
      <div className="box">
        <article className="media">
          <div className="media-left">
            <figure className="image is-64x64">
              <img
                alt="Image"
                src="https://s3.amazonaws.com/uifaces/faces/twitter/zeldman/128.jpg"
              />
            </figure>
          </div>
          <div className="media-content">
            <div className="content">
              <p>
                <strong> John Smith </strong> <small> @johnsmith </small>
                <small> 31m </small>
                <br /> Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Aenean efficitur sit amet massa fringilla egestas. Nullam
                condimentum luctus turpis.
              </p>
            </div>
            <nav className="level">
              <div className="level-left">
                <a className="level-item">
                  <span className="icon is-small">
                    <i className="fa fa-reply"> </i>
                  </span>
                </a>
                <a className="level-item">
                  <span className="icon is-small">
                    <i className="fa fa-retweet"> </i>
                  </span>
                </a>
                <a className="level-item">
                  <span className="icon is-small">
                    <i className="fa fa-heart"> </i>
                  </span>
                </a>
              </div>
            </nav>
          </div>
        </article>
      </div>
    </section>

    <section className="section" id="button">
      <h1 className="title"> Button </h1>
      <hr />
      <div className="columns">
        <div className="column">
          <div className="buttons">
            <a className="button"> Button </a>
            <a className="button is-white"> White </a>
            <a className="button is-light"> Light </a>
            <a className="button is-dark"> Dark </a>
            <a className="button is-black"> Black </a>
            <a className="button is-link"> Link </a>
            <a className="button is-text"> Link </a>
          </div>
          <div className="buttons">
            <a className="button is-primary"> Primary </a>
            <a className="button is-info"> Info </a>
            <a className="button is-success"> Success </a>
            <a className="button is-warning"> Warning </a>
            <a className="button is-danger"> Danger </a>
          </div>
          <div className="buttons">
            <a className="button is-focused"> Focus </a>
            <a className="button is-primary is-focused"> Focus </a>
            <a className="button is-info is-focused"> Focus </a>
            <a className="button is-success is-focused"> Focus </a>
            <a className="button is-warning is-focused"> Focus </a>
            <a className="button is-danger is-focused"> Focus </a>
          </div>
          <div className="buttons">
            <a className="button is-hovered"> Hover </a>
            <a className="button is-primary is-hovered"> Hover </a>
            <a className="button is-info is-hovered"> Hover </a>
            <a className="button is-success is-hovered"> Hover </a>
            <a className="button is-warning is-hovered"> Hover </a>
            <a className="button is-danger is-hovered"> Hover </a>
          </div>
          <div className="buttons">
            <a className="button is-active"> Active </a>
            <a className="button is-primary is-active"> Active </a>
            <a className="button is-info is-active"> Active </a>
            <a className="button is-success is-active"> Active </a>
            <a className="button is-warning is-active"> Active </a>
            <a className="button is-danger is-active"> Active </a>
          </div>
          <div className="buttons">
            <a className="button is-loading"> Loading </a>
            <a className="button is-primary is-loading"> Loading </a>
            <a className="button is-info is-loading"> Loading </a>
            <a className="button is-success is-loading"> Loading </a>
            <a className="button is-warning is-loading"> Loading </a>
            <a className="button is-danger is-loading"> Loading </a>
          </div>
          <p className="field">
            <a className="button">
              <span className="icon is-small">
                <i className="fa fa-bold"> </i>
              </span>
            </a>
            <a className="button">
              <span className="icon is-small">
                <i className="fa fa-italic"> </i>
              </span>
            </a>
            <a className="button">
              <span className="icon is-small">
                <i className="fa fa-underline"> </i>
              </span>
            </a>
            <a className="button">
              <span className="icon">
                <i className="fa fa-github"> </i>
              </span>
              <span> GitHub </span>
            </a>
            <a className="button is-primary">
              <span className="icon">
                <i className="fa fa-twitter"> </i>
              </span>
              <span> Twitter </span>
            </a>
            <a className="button is-success">
              <span className="icon is-small">
                <i className="fa fa-check"> </i>
              </span>
              <span> Save </span>
            </a>
            <a className="button is-danger is-outlined">
              <span> Delete </span>
              <span className="icon is-small">
                <i className="fa fa-times"> </i>
              </span>
            </a>
          </p>
          <div className="field has-addons">
            <p className="control">
              <a className="button">
                <span className="icon is-small">
                  <i className="fa fa-bold"></i>
                </span>
                <span>Bold</span>
              </a>
            </p>
            <p className="control">
              <a className="button">
                <span className="icon is-small">
                  <i className="fa fa-italic"></i>
                </span>
                <span>Italic</span>
              </a>
            </p>
            <p className="control">
              <a className="button">
                <span className="icon is-small">
                  <i className="fa fa-underline"></i>
                </span>
                <span>Underline</span>
              </a>
            </p>
          </div>

          <div className="field has-addons">
            <p className="control">
              <a className="button">
                <span className="icon is-small">
                  <i className="fa fa-align-left"></i>
                </span>
                <span>Left</span>
              </a>
            </p>
            <p className="control">
              <a className="button">
                <span className="icon is-small">
                  <i className="fa fa-align-center"></i>
                </span>
                <span>Center</span>
              </a>
            </p>
            <p className="control">
              <a className="button">
                <span className="icon is-small">
                  <i className="fa fa-align-right"></i>
                </span>
                <span>Right</span>
              </a>
            </p>
          </div>
          <div className="buttons">
            <a className="button is-rounded">Rounded</a>
            <a className="button is-primary is-rounded">Rounded</a>
            <a className="button is-link is-rounded">Rounded</a>
            <a className="button is-info is-rounded">Rounded</a>
            <a className="button is-success is-rounded">Rounded</a>
            <a className="button is-danger is-rounded">Rounded</a>
          </div>
        </div>
        <div className="column">
          <div className="buttons">
            <a className="button is-small"> Small </a>
            <a className="button"> Normal </a>
            <a className="button is-medium"> Medium </a>
            <a className="button is-large"> Large </a>
          </div>
          <div className="buttons">
            <a className="button is-outlined"> Outlined </a>
            <a className="button is-primary is-outlined"> Outlined </a>
            <a className="button is-info is-outlined"> Outlined </a>
            <a className="button is-success is-outlined"> Outlined </a>
            <a className="button is-danger is-outlined"> Outlined </a>
          </div>
          <div className="buttons notification is-primary">
            <a className="button is-primary is-inverted is-outlined">
              Invert Outlined
            </a>
            <a className="button is-info is-inverted is-outlined">
              Invert Outlined
            </a>
            <a className="button is-success is-inverted is-outlined">
              Invert Outlined
            </a>
            <a className="button is-danger is-inverted is-outlined">
              Invert Outlined
            </a>
          </div>
          <div className="buttons notification is-primary">
            <a className="button is-primary is-inverted"> Inverted </a>
            <a className="button is-info is-inverted"> Inverted </a>
            <a className="button is-success is-inverted"> Inverted </a>
            <a className="button is-danger is-inverted"> Inverted </a>
          </div>
          <p className="buttons">
            <a className="button is-small">
              <span className="icon is-small">
                <i className="fa fa-github"> </i>
              </span>
              <span> GitHub </span>
            </a>
            <a className="button">
              <span className="icon">
                <i className="fa fa-github"> </i>
              </span>
              <span> GitHub </span>
            </a>
            <a className="button is-medium">
              <span className="icon">
                <i className="fa fa-github"> </i>
              </span>
              <span> GitHub </span>
            </a>
            <a className="button is-large">
              <span className="icon is-medium">
                <i className="fa fa-github"> </i>
              </span>
              <span> GitHub </span>
            </a>
          </p>
          <p className="field">
            <a className="button is-small">
              <span className="icon is-small">
                <i className="fa fa-header"> </i>
              </span>
            </a>
          </p>
          <p className="field">
            <a className="button">
              <span className="icon is-small">
                <i className="fa fa-header"> </i>
              </span>
            </a>
            <a className="button">
              <span className="icon">
                <i className="fa fa-header fa-lg"> </i>
              </span>
            </a>
          </p>
          <p className="field">
            <a className="button is-medium">
              <span className="icon is-small">
                <i className="fa fa-header"> </i>
              </span>
            </a>
            <a className="button is-medium">
              <span className="icon">
                <i className="fa fa-header fa-lg"> </i>
              </span>
            </a>
            <a className="button is-medium">
              <span className="icon is-medium">
                <i className="fa fa-header fa-2x"> </i>
              </span>
            </a>
          </p>
          <p className="field">
            <a className="button is-large">
              <span className="icon is-small">
                <i className="fa fa-header"> </i>
              </span>
            </a>
            <a className="button is-large">
              <span className="icon is-medium">
                <i className="fa fa-header fa-lg"> </i>
              </span>
            </a>
            <a className="button is-large">
              <span className="icon is-large">
                <i className="fa fa-header fa-2x"> </i>
              </span>
            </a>
          </p>
        </div>
      </div>
    </section>
    <section className="section" id="content">
      <h1 className="title"> Content </h1>
      <hr />
      <div className="content">
        <h1> Hello World </h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
          accumsan, metus ultrices eleifend gravida, nulla nunc varius lectus,
          nec rutrum justo nibh eu lectus. Ut vulputate semper dui. Fusce erat
          odio, sollicitudin vel erat vel, interdum mattis neque.
        </p>
        <h2> Second level </h2>
        <p>
          Curabitur accumsan turpis pharetra <strong>augue tincidunt</strong>
          blandit. Quisque condimentum maximus mi, sit amet commodo arcu rutrum
          id. Proin pretium urna vel cursus venenatis. Suspendisse potenti.
          Etiam mattis sem rhoncus lacus dapibus facilisis. Donec at dignissim
          dui. Ut et neque nisl.
        </p>
        <ul>
          <li> In fermentum leo eu lectus mollis, quis dictum mi aliquet. </li>
          <li> Morbi eu nulla lobortis, lobortis est in, fringilla felis. </li>
          <li>
            Aliquam nec felis in sapien venenatis viverra fermentum nec lectus.
          </li>
          <li> Ut non enim metus. </li>
        </ul>
        <h3> Third level </h3>
        <p>
          Quisque ante lacus, malesuada ac auctor vitae, congue
          <a href="#"> non ante </a>. Phasellus lacus ex, semper ac tortor nec,
          fringilla condimentum orci. Fusce eu rutrum tellus.
        </p>
        <ol>
          <li> Donec blandit a lorem id convallis. </li>
          <li> Cras gravida arcu at diam gravida gravida. </li>
          <li> Integer in volutpat libero. </li>
          <li> Donec a diam tellus. </li>
          <li> Aenean nec tortor orci. </li>
          <li>Quisque aliquam cursus urna, non bibendum massa viverra eget.</li>
          <li> Vivamus maximus ultricies pulvinar. </li>
        </ol>
        <blockquote>
          Ut venenatis, nisl scelerisque sollicitudin fermentum, quam libero
          hendrerit ipsum, ut blandit est tellus sit amet turpis.
        </blockquote>
        <p>
          Quisque at semper enim, eu hendrerit odio. Etiam auctor nisl et
          <em> justo sodales </em> elementum. Maecenas ultrices lacus quis neque
          consectetur, et lobortis nisi molestie.
        </p>
        <p>
          Sed sagittis enim ac tortor maximus rutrum. Nulla facilisi. Donec
          mattis vulputate risus in luctus. Maecenas vestibulum interdum
          commodo.
        </p>
        <p>
          Suspendisse egestas sapien non felis placerat elementum. Morbi tortor
          nisl, suscipit sed mi sit amet, mollis malesuada nulla. Nulla
          facilisi. Nullam ac erat ante.
        </p>
        <h4> Fourth level </h4>
        <p>
          Nulla efficitur eleifend nisi, sit amet bibendum sapien fringilla ac.
          Mauris euismod metus a tellus laoreet, at elementum ex efficitur.
        </p>
        <p>
          Maecenas eleifend sollicitudin dui faucibus sollicitudin augue cursus
          non. Ut finibus eleifend arcu ut vehicula. Mauris eu est maximus est
          porta condimentum in eu justo. Nulla id iaculis sapien.
        </p>
        <table>
          <thead>
            <tr>
              <th> One </th>
              <th> Two </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> Three </td>
              <td> Four </td>
            </tr>
            <tr>
              <td> Five </td>
              <td> Six </td>
            </tr>
            <tr>
              <td> Seven </td>
              <td> Eight </td>
            </tr>
            <tr>
              <td> Nine </td>
              <td> Ten </td>
            </tr>
            <tr>
              <td> Eleven </td>
              <td> Twelve </td>
            </tr>
          </tbody>
        </table>
        <p>
          Phasellus porttitor enim id metus volutpat ultricies. Ut nisi nunc,
          blandit sed dapibus at, vestibulum in felis. Etiam iaculis lorem ac
          nibh bibendum rhoncus. Nam interdum efficitur ligula sit amet
          ullamcorper. Etiam tristique, leo vitae porta faucibus, mi lacus
          laoreet metus, at cursus leo est vel tellus. Sed ac posuere est. Nunc
          ultricies nunc neque, vitae ultricies ex sodales quis. Aliquam eu nibh
          in libero accumsan pulvinar. Nullam nec nisl placerat, pretium metus
          vel, euismod ipsum. Proin tempor cursus nisl vel condimentum. Nam
          pharetra varius metus non pellentesque.
        </p>
        <h5> Fifth level </h5>
        <p>
          Aliquam sagittis rhoncus vulputate. Cras non luctus sem, sed tincidunt
          ligula. Vestibulum at nunc elit. Praesent aliquet ligula mi, in luctus
          elit volutpat porta. Phasellus molestie diam vel nisi sodales, a
          eleifend augue laoreet. Sed nec eleifend justo. Nam et sollicitudin
          odio.
        </p>
        <h6> Sixth level </h6>
        <p>
          Cras in nibh lacinia, venenatis nisi et, auctor urna. Donec pulvinar
          lacus sed diam dignissim, ut eleifend eros accumsan. Phasellus non
          tortor eros. Ut sed rutrum lacus. Etiam purus nunc, scelerisque quis
          enim vitae, malesuada ultrices turpis. Nunc vitae maximus purus, nec
          consectetur dui. Suspendisse euismod, elit vel rutrum commodo, ipsum
          tortor maximus dui, sed varius sapien odio vitae est. Etiam at cursus
          metus.
        </p>
      </div>
    </section>

    <section className="section" id="delete">
      <h1 className="title"> Delete </h1>
      <hr />
      <div className="block">
        <span className="tag is-success">
          Hello World
          <button className="delete is-small"> </button>
        </span>
        <a className="delete is-small"> </a>
        <a className="delete"> </a>
        <a className="delete is-medium"> </a>
        <a className="delete is-large"> </a>
      </div>
      <div className="notification is-danger">
        <button className="delete"> </button> Lorem ipsum dolor sit amet,
        consectetur adipiscing elit lorem ipsum dolor sit amet, consectetur
        adipiscing elit
      </div>
      <article className="message is-info">
        <div className="message-header">
          Info
          <button className="delete"> </button>
        </div>
        <div className="message-body">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
          risus mi, tempus quis placerat ut, porta nec nulla. Vestibulum rhoncus
          ac ex sit amet fringilla. Nullam gravida purus diam, et dictum felis
          venenatis efficitur. Aenean ac eleifend lacus, in mollis lectus. Donec
          sodales, arcu et sollicitudin porttitor, tortor urna tempor ligula, id
          porttitor mi magna a neque. Donec dui urna, vehicula et sem eget,
          facilisis sodales sem.
        </div>
      </article>
    </section>

    <section className="section" id="form">
      <h1 className="title"> Form </h1>
      <hr />
      <div className="columns">
        <div className="column">
          <div className="field">
            <label className="label">Name</label>
            <p className="control">
              <input className="input" type="text" placeholder="Text input" />
            </p>
          </div>
          <div className="field">
            <label className="label">Username</label>
            <p className="control has-icons-left has-icons-right">
              <input
                className="input is-success"
                type="text"
                placeholder="Text input"
                value="bulma"
              />
              <span className="icon is-small is-left">
                <i className="fa fa-user"></i>
              </span>
              <span className="icon is-small is-right">
                <i className="fa fa-check"></i>
              </span>
            </p>
            <p className="help is-success">This username is available</p>
          </div>
          <div className="field">
            <label className="label">Email</label>
            <p className="control has-icons-left has-icons-right">
              <input
                className="input is-danger"
                type="text"
                placeholder="Email input"
                value="hello@"
              />
              <span className="icon is-small is-left">
                <i className="fa fa-envelope"></i>
              </span>
              <span className="icon is-small is-right">
                <i className="fa fa-warning"></i>
              </span>
            </p>
            <p className="help is-danger">This email is invalid</p>
          </div>
          <div className="field">
            <label className="label">Subject</label>
            <p className="control">
              <span className="select">
                <select>
                  <option>Select dropdown</option>
                  <option>With options</option>
                </select>
              </span>
            </p>
          </div>
          <div className="field">
            <label className="label">Message</label>
            <p className="control">
              <textarea className="textarea" placeholder="Textarea"></textarea>
            </p>
          </div>
          <div className="field">
            <p className="control">
              <label className="checkbox">
                <input type="checkbox" /> I agree to the
                <a href="#">terms and conditions</a>
              </label>
            </p>
          </div>
          <div className="field">
            <p className="control">
              <label className="radio">
                <input type="radio" name="question" /> Yes
              </label>
              <label className="radio">
                <input type="radio" name="question" /> No
              </label>
            </p>
          </div>
          <div className="field is-grouped">
            <p className="control">
              <button className="button is-primary">Submit</button>
            </p>
            <p className="control">
              <button className="button is-link">Cancel</button>
            </p>
          </div>
          <br />
          <h4 className="subtitle"> Disabled </h4>
          <hr />
          <div className="field">
            <p className="control">
              <input
                className="input"
                disabled=""
                placeholder="Disabled input"
                type="text"
              />
            </p>
          </div>
          <div className="field">
            <p className="control">
              <textarea
                className="textarea"
                disabled=""
                placeholder="Disabled textarea"
              ></textarea>
            </p>
          </div>
          <div className="field">
            <p className="control">
              <label className="checkbox is-disabled">
                <input disabled="" type="checkbox" /> Remember me
              </label>
            </p>
          </div>
          <div className="field">
            <p className="control">
              <label className="radio is-disabled">
                <input disabled="" name="question" type="radio" /> Yes
              </label>
              <label className="radio is-disabled">
                <input disabled="" name="question" type="radio" /> No
              </label>
            </p>
          </div>
          <div className="field is-grouped">
            <p className="control">
              <button className="button is-primary" disabled="">
                Submit
              </button>
            </p>
            <p className="control">
              <button className="button" disabled="">
                Cancel
              </button>
            </p>
          </div>
          <br />
          <h3 className="title"> Horizontal Form </h3>
          <hr />
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">From</label>
            </div>
            <div className="field-body">
              <div className="field is-grouped">
                <p className="control is-expanded has-icons-left">
                  <input className="input" type="text" placeholder="Name" />
                  <span className="icon is-small is-left">
                    <i className="fa fa-user"></i>
                  </span>
                </p>
              </div>
              <div className="field">
                <p className="control is-expanded has-icons-left has-icons-right">
                  <input
                    className="input is-success"
                    type="email"
                    placeholder="Email"
                    value="alex@smith.com"
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-envelope"></i>
                  </span>
                  <span className="icon is-small is-right">
                    <i className="fa fa-check"></i>
                  </span>
                </p>
                <p className="help is-success">This email is correct</p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Department</label>
            </div>
            <div className="field-body">
              <div className="field is-narrow">
                <div className="control">
                  <div className="select is-fullwidth">
                    <select>
                      <option>Business development</option>
                      <option>Marketing</option>
                      <option>Sales</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label">
              <label className="label">Already a member?</label>
            </div>
            <div className="field-body">
              <div className="field is-narrow">
                <div className="control">
                  <label className="radio">
                    <input type="radio" name="member" /> Yes
                  </label>
                  <label className="radio">
                    <input type="radio" name="member" /> No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Subject</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    className="input is-danger"
                    type="text"
                    placeholder="e.g. Partnership opportunity "
                  />
                </div>
                <p className="help is-danger">This field is required</p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Question</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <textarea
                    className="textarea"
                    placeholder="Explain how we can help you"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label"></div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <button className="button is-primary">Send message</button>
                </div>
              </div>
            </div>
          </div>
          <br />
          <h3 className="title">File</h3>
          <hr />
          <div className="field">
            <div className="file">
              <label className="file-label">
                <input className="file-input" type="file" name="resume" />
                <span className="file-cta">
                  <span className="file-icon">
                    <i className="fa fa-upload"></i>
                  </span>
                  <span className="file-label">Choose a file…</span>
                </span>
              </label>
            </div>
          </div>
          <div className="field">
            <div className="file has-name">
              <label className="file-label">
                <input className="file-input" type="file" name="resume" />
                <span className="file-cta">
                  <span className="file-icon">
                    <i className="fa fa-upload"></i>
                  </span>
                  <span className="file-label">Choose a file…</span>
                </span>
                <span className="file-name">
                  Screen Shot 2017-07-29 at 15.54.25.png
                </span>
              </label>
            </div>
          </div>
          <div className="field">
            <div className="file is-primary">
              <label className="file-label">
                <input className="file-input" type="file" name="resume" />
                <span className="file-cta">
                  <span className="file-icon">
                    <i className="fa fa-upload"></i>
                  </span>
                  <span className="file-label">Primary file…</span>
                </span>
              </label>
            </div>
          </div>

          <div className="field">
            <div className="file is-info has-name">
              <label className="file-label">
                <input className="file-input" type="file" name="resume" />
                <span className="file-cta">
                  <span className="file-icon">
                    <i className="fa fa-upload"></i>
                  </span>
                  <span className="file-label">Info file…</span>
                </span>
                <span className="file-name">
                  Screen Shot 2017-07-29 at 15.54.25.png
                </span>
              </label>
            </div>
          </div>

          <div className="field">
            <div className="file is-warning is-boxed">
              <label className="file-label">
                <input className="file-input" type="file" name="resume" />
                <span className="file-cta">
                  <span className="file-icon">
                    <i className="fa fa-cloud-upload"></i>
                  </span>
                  <span className="file-label">Warning file…</span>
                </span>
              </label>
            </div>
          </div>

          <div className="field">
            <div className="file is-danger has-name is-boxed">
              <label className="file-label">
                <input className="file-input" type="file" name="resume" />
                <span className="file-cta">
                  <span className="file-icon">
                    <i className="fa fa-cloud-upload"></i>
                  </span>
                  <span className="file-label">Danger file…</span>
                </span>
                <span className="file-name">
                  Screen Shot 2017-07-29 at 15.54.25.png
                </span>
              </label>
            </div>
          </div>
        </div>
        <div className="column">
          <br />
          <h3 className="subtitle"> Styles </h3>
          <hr />
          <div className="field">
            <p className="control">
              <input
                className="input is-rounded"
                type="text"
                placeholder="Rounded input"
              />
            </p>
          </div>
          <br />
          <h3 className="subtitle"> Colors </h3>
          <hr />
          <div className="field">
            <p className="control">
              <input
                className="input is-primary"
                type="text"
                placeholder="Primary input"
              />
            </p>
          </div>
          <div className="field">
            <p className="control">
              <input
                className="input is-info"
                type="text"
                placeholder="Info input"
              />
            </p>
          </div>
          <div className="field">
            <p className="control">
              <input
                className="input is-success"
                type="text"
                placeholder="Success input"
              />
            </p>
          </div>
          <div className="field">
            <p className="control">
              <input
                className="input is-warning"
                type="text"
                placeholder="Warning input"
              />
            </p>
          </div>
          <div className="field">
            <p className="control">
              <input
                className="input is-danger"
                type="text"
                placeholder="Danger input"
              />
            </p>
          </div>
          <br />
          <h3 className="subtitle"> Sizes </h3>
          <hr />
          <div className="field">
            <p className="control">
              <input
                className="input is-small"
                type="text"
                placeholder="Small input"
              />
            </p>
          </div>
          <div className="field">
            <p className="control">
              <input className="input" type="text" placeholder="Normal input" />
            </p>
          </div>
          <div className="field">
            <p className="control">
              <input
                className="input is-medium"
                type="text"
                placeholder="Medium input"
              />
            </p>
          </div>
          <div className="field">
            <p className="control">
              <input
                className="input is-large"
                type="text"
                placeholder="Large input"
              />
            </p>
          </div>
          <div className="field">
            <p className="control">
              <span className="select is-small">
                <select>
                  <option>Select dropdown</option>
                  <option>With options</option>
                </select>
              </span>
            </p>
          </div>
          <div className="field">
            <p className="control">
              <span className="select">
                <select>
                  <option>Select dropdown</option>
                  <option>With options</option>
                </select>
              </span>
            </p>
          </div>
          <div className="field">
            <p className="control">
              <span className="select is-medium">
                <select>
                  <option>Select dropdown</option>
                  <option>With options</option>
                </select>
              </span>
            </p>
          </div>
          <div className="field">
            <p className="control">
              <span className="select is-large">
                <select>
                  <option>Select dropdown</option>
                  <option>With options</option>
                </select>
              </span>
            </p>
          </div>
          <div className="field">
            <label className="label is-small">Small input</label>
            <p className="control has-icons-left has-icons-right">
              <input
                className="input is-small"
                type="email"
                placeholder="Email"
              />
              <span className="icon is-small is-left">
                <i className="fa fa-envelope"></i>
              </span>
              <span className="icon is-small is-right">
                <i className="fa fa-check"></i>
              </span>
            </p>
          </div>
          <div className="field">
            <label className="label">Normal input</label>
            <p className="control has-icons-left has-icons-right">
              <input className="input" type="email" placeholder="Email" />
              <span className="icon is-small is-left">
                <i className="fa fa-envelope"></i>
              </span>
              <span className="icon is-small is-right">
                <i className="fa fa-check"></i>
              </span>
            </p>
          </div>
          <div className="field">
            <p className="control has-icons-left has-icons-right">
              <input className="input" type="email" placeholder="Email" />
              <span className="icon is-left">
                <i className="fa fa-envelope"></i>
              </span>
              <span className="icon is-right">
                <i className="fa fa-check"></i>
              </span>
            </p>
          </div>
          <div className="field">
            <label className="label is-medium">Medium input</label>
            <p className="control has-icons-left has-icons-right">
              <input
                className="input is-medium"
                type="email"
                placeholder="Email"
              />
              <span className="icon is-small is-left">
                <i className="fa fa-envelope"></i>
              </span>
              <span className="icon is-small is-right">
                <i className="fa fa-check"></i>
              </span>
            </p>
          </div>
          <div className="field">
            <p className="control has-icons-left has-icons-right">
              <input
                className="input is-medium"
                type="email"
                placeholder="Email"
              />
              <span className="icon is-left">
                <i className="fa fa-envelope"></i>
              </span>
              <span className="icon is-right">
                <i className="fa fa-check"></i>
              </span>
            </p>
          </div>
          <div className="field">
            <p className="control has-icons-left has-icons-right">
              <input
                className="input is-medium"
                type="email"
                placeholder="Email"
              />
              <span className="icon is-medium is-left">
                <i className="fa fa-envelope"></i>
              </span>
              <span className="icon is-medium is-right">
                <i className="fa fa-check"></i>
              </span>
            </p>
          </div>
          <div className="field">
            <label className="label is-large">Large input</label>
            <p className="control has-icons-left has-icons-right">
              <input
                className="input is-large"
                type="email"
                placeholder="Email"
              />
              <span className="icon is-small is-left">
                <i className="fa fa-envelope"></i>
              </span>
              <span className="icon is-small is-right">
                <i className="fa fa-check"></i>
              </span>
            </p>
          </div>
          <div className="field">
            <p className="control has-icons-left has-icons-right">
              <input
                className="input is-large"
                type="email"
                placeholder="Email"
              />
              <span className="icon is-left">
                <i className="fa fa-envelope"></i>
              </span>
              <span className="icon is-right">
                <i className="fa fa-check"></i>
              </span>
            </p>
          </div>
          <div className="field">
            <p className="control has-icons-left has-icons-right">
              <input
                className="input is-large"
                type="email"
                placeholder="Email"
              />
              <span className="icon is-medium is-left">
                <i className="fa fa-envelope"></i>
              </span>
              <span className="icon is-medium is-right">
                <i className="fa fa-check"></i>
              </span>
            </p>
          </div>
          <div className="field">
            <p className="control has-icons-left has-icons-right">
              <input
                className="input is-large"
                type="email"
                placeholder="Email"
              />
              <span className="icon is-large is-left">
                <i className="fa fa-envelope"></i>
              </span>
              <span className="icon is-large is-right">
                <i className="fa fa-check"></i>
              </span>
            </p>
          </div>
          <br />
          <h4 className="subtitle"> With Font Awesome icons </h4>
          <hr />
          <div className="field">
            <p className="control has-icons-left">
              <input className="input" type="email" placeholder="Email" />
              <span className="icon is-small is-left">
                <i className="fa fa-envelope"></i>
              </span>
            </p>
          </div>
          <div className="field">
            <p className="control has-icons-left">
              <input className="input" type="password" placeholder="Password" />
              <span className="icon is-small is-left">
                <i className="fa fa-lock"></i>
              </span>
            </p>
          </div>
          <div className="field">
            <p className="control">
              <button className="button is-success">Login</button>
            </p>
          </div>
          <br />
          <h3 className="title"> Form addons </h3>
          <hr />
          <div className="field has-addons">
            <p className="control">
              <input
                className="input"
                type="text"
                placeholder="Find a repository"
              />
            </p>
            <p className="control">
              <a className="button is-info">Search</a>
            </p>
          </div>
          <div className="field has-addons">
            <p className="control">
              <input
                className="input is-large"
                type="text"
                placeholder="Find a repository"
              />
            </p>
            <p className="control">
              <a className="button is-info is-large">Search</a>
            </p>
          </div>
          <div className="field has-addons">
            <p className="control">
              <span className="select">
                <select>
                  <option>$</option>
                  <option>£</option>
                  <option>€</option>
                </select>
              </span>
            </p>
            <p className="control">
              <input
                className="input"
                type="text"
                placeholder="Amount of money"
              />
            </p>
            <p className="control">
              <a className="button">Transfer</a>
            </p>
          </div>
          <div className="field has-addons">
            <p className="control">
              <span className="select">
                <select>
                  <option>$</option>
                  <option>£</option>
                  <option>€</option>
                </select>
              </span>
            </p>
            <p className="control is-expanded">
              <input
                className="input"
                type="text"
                placeholder="Amount of money"
              />
            </p>
            <p className="control">
              <a className="button">Transfer</a>
            </p>
          </div>
          <div className="field has-addons">
            <p className="control is-expanded">
              <span className="select is-fullwidth">
                <select name="country">
                  <option value="Argentina">Argentina</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Brazil">Brazil</option>
                  <option value="Chile">Chile</option>
                  <option value="Colombia">Colombia</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="Guyana">Guyana</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Peru">Peru</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Uruguay">Uruguay</option>
                  <option value="Venezuela">Venezuela</option>
                </select>
              </span>
            </p>
            <p className="control">
              <button type="submit" className="button is-primary">
                Choose
              </button>
            </p>
          </div>
          <div className="field is-grouped">
            <p className="control is-expanded">
              <input
                className="input"
                type="text"
                placeholder="Find a repository"
              />
            </p>
            <p className="control">
              <a className="button is-info">Search</a>
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="section" id="icon">
      <h1 className="title"> Icons </h1>
      <hr />
      <span className="icon is-small">
        <i className="fa fa-home"> </i>
      </span>
      <span className="icon">
        <i className="fa fa-home"> </i>
      </span>
      <span className="icon is-medium">
        <i className="fa fa-home"> </i>
      </span>
      <span className="icon is-large">
        <i className="fa fa-home"> </i>
      </span>
    </section>
    <section className="section" id="notifications">
      <h1 className="title"> Notifications </h1>
      <hr />
      <div className="columns is-multiline">
        <div className="column is-half">
          <div className="notification ">
            <button className="delete"> </button> Lorem ipsum dolor sit amet,
            <a href="#">consectetur</a> adipiscing elit lorem ipsum dolor sit
            amet, consectetur adipiscing elit
          </div>
        </div>

        <div className="column is-half">
          <div className="notification is-primary">
            <button className="delete"> </button> Lorem ipsum dolor sit amet,
            <a href="#">consectetur</a> adipiscing elit lorem ipsum dolor sit
            amet, consectetur adipiscing elit
          </div>
        </div>

        <div className="column is-half">
          <div className="notification is-link">
            <button className="delete"> </button> Lorem ipsum dolor sit amet,
            <a href="#">consectetur</a> adipiscing elit lorem ipsum dolor sit
            amet, consectetur adipiscing elit
          </div>
        </div>

        <div className="column is-half">
          <div className="notification is-info">
            <button className="delete"> </button> Lorem ipsum dolor sit amet,
            <a href="#">consectetur</a> adipiscing elit lorem ipsum dolor sit
            amet, consectetur adipiscing elit
          </div>
        </div>

        <div className="column is-half">
          <div className="notification is-success">
            <button className="delete"> </button> Lorem ipsum dolor sit amet,
            <a href="#">consectetur</a> adipiscing elit lorem ipsum dolor sit
            amet, consectetur adipiscing elit
          </div>
        </div>

        <div className="column is-half">
          <div className="notification is-warning">
            <button className="delete"> </button> Lorem ipsum dolor sit amet,
            <a href="#">consectetur</a> adipiscing elit lorem ipsum dolor sit
            amet, consectetur adipiscing elit
          </div>
        </div>

        <div className="column is-half">
          <div className="notification is-danger">
            <button className="delete"> </button> Lorem ipsum dolor sit amet,
            <a href="#">consectetur</a> adipiscing elit lorem ipsum dolor sit
            amet, consectetur adipiscing elit
          </div>
        </div>
      </div>
    </section>
    <section className="section" id="progress">
      <h1 className="title"> Progress bars </h1>
      <hr />

      <progress className="progress " max="100" value="15">
        15%
      </progress>

      <progress className="progress is-primary" max="100" value="15">
        15%
      </progress>

      <progress className="progress is-link" max="100" value="15">
        15%
      </progress>

      <progress className="progress is-info" max="100" value="15">
        15%
      </progress>

      <progress className="progress is-success" max="100" value="15">
        15%
      </progress>

      <progress className="progress is-warning" max="100" value="15">
        15%
      </progress>

      <progress className="progress is-danger" max="100" value="15">
        15%
      </progress>

      <progress className="progress is-small" max="100" value="15">
        15%
      </progress>
      <progress className="progress" max="100" value="30">
        30%
      </progress>
      <progress className="progress is-medium" max="100" value="45">
        45%
      </progress>
      <progress className="progress is-large" max="100" value="60">
        60%
      </progress>
      <h2 className="title is-2">Indeterminate</h2>
      <progress className="progress is-small is-primary" max="100">
        15%
      </progress>
      <progress className="progress is-danger" max="100">
        30%
      </progress>
      <progress className="progress is-medium is-dark" max="100">
        45%
      </progress>
      <progress className="progress is-large is-info" max="100">
        60%
      </progress>
    </section>
    <section className="section" id="tag">
      <h1 className="title"> Tag </h1>
      <hr />
      <div className="tags">
        <span className="tag is-primary"> Primary </span>

        <span className="tag is-link"> Link </span>

        <span className="tag is-info"> Info </span>

        <span className="tag is-success"> Success </span>

        <span className="tag is-warning"> Warning </span>

        <span className="tag is-danger"> Danger </span>

        <span className="tag is-white"> White </span>

        <span className="tag is-black"> Black </span>

        <span className="tag is-light"> Light </span>

        <span className="tag is-dark"> Dark </span>

        <span className="tag is-primary is-medium"> Medium </span>
        <span className="tag is-info is-large"> Large </span>
        <span className="tag is-success">
          Bar
          <button className="delete is-small"> </button>
        </span>
        <span className="tag is-warning is-medium">
          Hello
          <button className="delete is-small"> </button>
        </span>
        <span className="tag is-danger is-large">
          World
          <button className="delete"> </button>
        </span>
      </div>
      <div className="field is-grouped is-grouped-multiline">
        <div className="control">
          <div className="tags has-addons">
            <span className="tag is-dark">npm</span>
            <span className="tag is-info">0.5.0</span>
          </div>
        </div>

        <div className="control">
          <div className="tags has-addons">
            <span className="tag is-dark">build</span>
            <span className="tag is-success">passing</span>
          </div>
        </div>

        <div className="control">
          <div className="tags has-addons">
            <span className="tag is-dark">chat</span>
            <span className="tag is-primary">on gitter</span>
          </div>
        </div>
      </div>
      <div className="field is-grouped is-grouped-multiline">
        <div className="control">
          <div className="tags has-addons">
            <a className="tag is-link">Technology</a>
            <a className="tag is-delete"></a>
          </div>
        </div>

        <div className="control">
          <div className="tags has-addons">
            <a className="tag is-link">CSS</a>
            <a className="tag is-delete"></a>
          </div>
        </div>

        <div className="control">
          <div className="tags has-addons">
            <a className="tag is-link">Flexbox</a>
            <a className="tag is-delete"></a>
          </div>
        </div>

        <div className="control">
          <div className="tags has-addons">
            <a className="tag is-link">Web Design</a>
            <a className="tag is-delete"></a>
          </div>
        </div>

        <div className="control">
          <div className="tags has-addons">
            <a className="tag is-link">Open Source</a>
            <a className="tag is-delete"></a>
          </div>
        </div>

        <div className="control">
          <div className="tags has-addons">
            <a className="tag is-link">Community</a>
            <a className="tag is-delete"></a>
          </div>
        </div>

        <div className="control">
          <div className="tags has-addons">
            <a className="tag is-link">Documentation</a>
            <a className="tag is-delete"></a>
          </div>
        </div>
      </div>
    </section>
    <section className="section" id="navbar">
      <h1 className="title">Navbar</h1>
      <hr />

      <nav className="navbar ">
        <div className="navbar-brand">
          <a className="navbar-item" href="https://bulma.io">
            <img
              src="https://bulma.io/images/bulma-logo.png"
              alt="Bulma: a modern CSS framework based on Flexbox"
              width="112"
              height="28"
            />
          </a>
          <a
            className="navbar-item is-hidden-desktop"
            href="https://github.com/jgthms/bulma"
            target="_blank"
          >
            <span className="icon" style={{ color: '#333' }}>
              <i className="fa fa-github"></i>
            </span>
          </a>
          <a
            className="navbar-item is-hidden-desktop"
            href="https://twitter.com/jgthms"
            target="_blank"
          >
            <span className="icon" style={{ color: '#55acee' }}>
              <i className="fa fa-twitter"></i>
            </span>
          </a>
          <div className="navbar-burger burger" data-target="navMenuExample1">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div id="navMenuExample1" className="navbar-menu">
          <div className="navbar-start">
            <a className="navbar-item " href="#">
              Home
            </a>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link  is-active" href="#">
                Docs
              </a>
              <div className="navbar-dropdown ">
                <a className="navbar-item " href="#">
                  Overview
                </a>
                <a className="navbar-item " href="#">
                  Modifiers
                </a>
                <a className="navbar-item " href="#">
                  Grid
                </a>
                <a className="navbar-item " href="#">
                  Form
                </a>
                <a className="navbar-item " href="#">
                  Elements
                </a>
                <a className="navbar-item is-active" href="#">
                  Components
                </a>
                <a className="navbar-item " href="#">
                  Layout
                </a>
                <hr className="navbar-divider" />
                <div className="navbar-item">
                  <div>
                    version
                    <p className="has-text-info is-size-6-desktop">0.4.3</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link " href="#blog/">
                Blog
              </a>
              <div
                id="blogDropdown"
                className="navbar-dropdown "
                data-style="width: 18rem;"
              >
                <a
                  className="navbar-item"
                  href="/2017/03/10/new-field-element/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">10 Mar 2017</small>
                    </p>
                    <p>New field element (for better controls)</p>
                  </div>
                </a>
                <a
                  className="navbar-item"
                  href="/2016/04/11/metro-ui-css-grid-with-bulma-tiles/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">11 Apr 2016</small>
                    </p>
                    <p>Metro UI CSS grid with Bulma tiles</p>
                  </div>
                </a>
                <a
                  className="navbar-item"
                  href="/2016/02/09/blog-launched-new-responsive-columns-new-helpers/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">09 Feb 2016</small>
                    </p>
                    <p>Blog launched, new responsive columns, new helpers</p>
                  </div>
                </a>
                <a className="navbar-item" href="#blog/">
                  More posts
                </a>
                <hr className="navbar-divider" />
                <div className="navbar-item">
                  <div className="navbar-content">
                    <div className="level is-mobile">
                      <div className="level-left">
                        <div className="level-item">
                          <strong>Stay up to date!</strong>
                        </div>
                      </div>
                      <div className="level-right">
                        <div className="level-item">
                          <a
                            className="button is-rss is-small"
                            href="#atom.xml"
                          >
                            <span className="icon is-small">
                              <i className="fa fa-rss"></i>
                            </span>
                            <span>Subscribe</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <div className="navbar-link">More</div>
              <div id="moreDropdown" className="navbar-dropdown ">
                <a className="navbar-item " href="#extensions/">
                  <div className="level is-mobile">
                    <div className="level-left">
                      <div className="level-item">
                        <p>
                          <strong>Extensions</strong>
                          <br />
                          <small>Side projects to enhance Bulma</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="navbar-end">
            <a
              className="navbar-item"
              href="https://github.com/jgthms/bulma"
              target="_blank"
            >
              Github
            </a>
            <a
              className="navbar-item"
              href="https://twitter.com/jgthms"
              target="_blank"
            >
              Twitter
            </a>
            <div className="navbar-item">
              <div className="field is-grouped">
                <p className="control">
                  <a id="twitter" className="button">
                    <span>Tweet</span>
                  </a>
                </p>
                <p className="control">
                  <a
                    className="button is-primary"
                    href="https://github.com/jgthms/bulma/archive/0.4.3.zip"
                  >
                    <span className="icon">
                      <i className="fa fa-download"></i>
                    </span>
                    <span>Download</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <br />

      <nav className="navbar is-primary">
        <div className="navbar-brand">
          <a className="navbar-item" href="https://bulma.io">
            <img
              src="https://bulma.io/images/bulma-logo.png"
              alt="Bulma: a modern CSS framework based on Flexbox"
              width="112"
              height="28"
            />
          </a>
          <a
            className="navbar-item is-hidden-desktop"
            href="https://github.com/jgthms/bulma"
            target="_blank"
          >
            <span className="icon" style={{ color: '#333' }}>
              <i className="fa fa-github"></i>
            </span>
          </a>
          <a
            className="navbar-item is-hidden-desktop"
            href="https://twitter.com/jgthms"
            target="_blank"
          >
            <span className="icon" style={{ color: '#55acee' }}>
              <i className="fa fa-twitter"></i>
            </span>
          </a>
          <div className="navbar-burger burger" data-target="navMenuExample2">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div id="navMenuExample2" className="navbar-menu">
          <div className="navbar-start">
            <a className="navbar-item " href="#">
              Home
            </a>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link  is-active" href="#">
                Docs
              </a>
              <div className="navbar-dropdown ">
                <a className="navbar-item " href="#">
                  Overview
                </a>
                <a className="navbar-item " href="#">
                  Modifiers
                </a>
                <a className="navbar-item " href="#">
                  Grid
                </a>
                <a className="navbar-item " href="#">
                  Form
                </a>
                <a className="navbar-item " href="#">
                  Elements
                </a>
                <a className="navbar-item is-active" href="#">
                  Components
                </a>
                <a className="navbar-item " href="#">
                  Layout
                </a>
                <hr className="navbar-divider" />
                <div className="navbar-item">
                  <div>
                    version
                    <p className="has-text-info is-size-6-desktop">0.4.3</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link " href="#blog/">
                Blog
              </a>
              <div
                id="blogDropdown"
                className="navbar-dropdown "
                data-style="width: 18rem;"
              >
                <a
                  className="navbar-item"
                  href="/2017/03/10/new-field-element/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">10 Mar 2017</small>
                    </p>
                    <p>New field element (for better controls)</p>
                  </div>
                </a>
                <a
                  className="navbar-item"
                  href="/2016/04/11/metro-ui-css-grid-with-bulma-tiles/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">11 Apr 2016</small>
                    </p>
                    <p>Metro UI CSS grid with Bulma tiles</p>
                  </div>
                </a>
                <a
                  className="navbar-item"
                  href="/2016/02/09/blog-launched-new-responsive-columns-new-helpers/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">09 Feb 2016</small>
                    </p>
                    <p>Blog launched, new responsive columns, new helpers</p>
                  </div>
                </a>
                <a className="navbar-item" href="#blog/">
                  More posts
                </a>
                <hr className="navbar-divider" />
                <div className="navbar-item">
                  <div className="navbar-content">
                    <div className="level is-mobile">
                      <div className="level-left">
                        <div className="level-item">
                          <strong>Stay up to date!</strong>
                        </div>
                      </div>
                      <div className="level-right">
                        <div className="level-item">
                          <a
                            className="button is-rss is-small"
                            href="#atom.xml"
                          >
                            <span className="icon is-small">
                              <i className="fa fa-rss"></i>
                            </span>
                            <span>Subscribe</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <div className="navbar-link">More</div>
              <div id="moreDropdown" className="navbar-dropdown ">
                <a className="navbar-item " href="#extensions/">
                  <div className="level is-mobile">
                    <div className="level-left">
                      <div className="level-item">
                        <p>
                          <strong>Extensions</strong>
                          <br />
                          <small>Side projects to enhance Bulma</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="navbar-end">
            <a
              className="navbar-item"
              href="https://github.com/jgthms/bulma"
              target="_blank"
            >
              Github
            </a>
            <a
              className="navbar-item"
              href="https://twitter.com/jgthms"
              target="_blank"
            >
              Twitter
            </a>
            <div className="navbar-item">
              <div className="field is-grouped">
                <p className="control">
                  <a id="twitter" className="button">
                    <span>Tweet</span>
                  </a>
                </p>
                <p className="control">
                  <a
                    className="button is-primary"
                    href="https://github.com/jgthms/bulma/archive/0.4.3.zip"
                  >
                    <span className="icon">
                      <i className="fa fa-download"></i>
                    </span>
                    <span>Download</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <br />

      <nav className="navbar is-link">
        <div className="navbar-brand">
          <a className="navbar-item" href="https://bulma.io">
            <img
              src="https://bulma.io/images/bulma-logo.png"
              alt="Bulma: a modern CSS framework based on Flexbox"
              width="112"
              height="28"
            />
          </a>
          <a
            className="navbar-item is-hidden-desktop"
            href="https://github.com/jgthms/bulma"
            target="_blank"
          >
            <span className="icon" style={{ color: '#333' }}>
              <i className="fa fa-github"></i>
            </span>
          </a>
          <a
            className="navbar-item is-hidden-desktop"
            href="https://twitter.com/jgthms"
            target="_blank"
          >
            <span className="icon" style={{ color: '#55acee' }}>
              <i className="fa fa-twitter"></i>
            </span>
          </a>
          <div className="navbar-burger burger" data-target="navMenuExample3">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div id="navMenuExample3" className="navbar-menu">
          <div className="navbar-start">
            <a className="navbar-item " href="#">
              Home
            </a>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link  is-active" href="#">
                Docs
              </a>
              <div className="navbar-dropdown ">
                <a className="navbar-item " href="#">
                  Overview
                </a>
                <a className="navbar-item " href="#">
                  Modifiers
                </a>
                <a className="navbar-item " href="#">
                  Grid
                </a>
                <a className="navbar-item " href="#">
                  Form
                </a>
                <a className="navbar-item " href="#">
                  Elements
                </a>
                <a className="navbar-item is-active" href="#">
                  Components
                </a>
                <a className="navbar-item " href="#">
                  Layout
                </a>
                <hr className="navbar-divider" />
                <div className="navbar-item">
                  <div>
                    version
                    <p className="has-text-info is-size-6-desktop">0.4.3</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link " href="#blog/">
                Blog
              </a>
              <div
                id="blogDropdown"
                className="navbar-dropdown "
                data-style="width: 18rem;"
              >
                <a
                  className="navbar-item"
                  href="/2017/03/10/new-field-element/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">10 Mar 2017</small>
                    </p>
                    <p>New field element (for better controls)</p>
                  </div>
                </a>
                <a
                  className="navbar-item"
                  href="/2016/04/11/metro-ui-css-grid-with-bulma-tiles/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">11 Apr 2016</small>
                    </p>
                    <p>Metro UI CSS grid with Bulma tiles</p>
                  </div>
                </a>
                <a
                  className="navbar-item"
                  href="/2016/02/09/blog-launched-new-responsive-columns-new-helpers/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">09 Feb 2016</small>
                    </p>
                    <p>Blog launched, new responsive columns, new helpers</p>
                  </div>
                </a>
                <a className="navbar-item" href="#blog/">
                  More posts
                </a>
                <hr className="navbar-divider" />
                <div className="navbar-item">
                  <div className="navbar-content">
                    <div className="level is-mobile">
                      <div className="level-left">
                        <div className="level-item">
                          <strong>Stay up to date!</strong>
                        </div>
                      </div>
                      <div className="level-right">
                        <div className="level-item">
                          <a
                            className="button is-rss is-small"
                            href="#atom.xml"
                          >
                            <span className="icon is-small">
                              <i className="fa fa-rss"></i>
                            </span>
                            <span>Subscribe</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <div className="navbar-link">More</div>
              <div id="moreDropdown" className="navbar-dropdown ">
                <a className="navbar-item " href="#extensions/">
                  <div className="level is-mobile">
                    <div className="level-left">
                      <div className="level-item">
                        <p>
                          <strong>Extensions</strong>
                          <br />
                          <small>Side projects to enhance Bulma</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="navbar-end">
            <a
              className="navbar-item"
              href="https://github.com/jgthms/bulma"
              target="_blank"
            >
              Github
            </a>
            <a
              className="navbar-item"
              href="https://twitter.com/jgthms"
              target="_blank"
            >
              Twitter
            </a>
            <div className="navbar-item">
              <div className="field is-grouped">
                <p className="control">
                  <a id="twitter" className="button">
                    <span>Tweet</span>
                  </a>
                </p>
                <p className="control">
                  <a
                    className="button is-primary"
                    href="https://github.com/jgthms/bulma/archive/0.4.3.zip"
                  >
                    <span className="icon">
                      <i className="fa fa-download"></i>
                    </span>
                    <span>Download</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <br />

      <nav className="navbar is-info">
        <div className="navbar-brand">
          <a className="navbar-item" href="https://bulma.io">
            <img
              src="https://bulma.io/images/bulma-logo.png"
              alt="Bulma: a modern CSS framework based on Flexbox"
              width="112"
              height="28"
            />
          </a>
          <a
            className="navbar-item is-hidden-desktop"
            href="https://github.com/jgthms/bulma"
            target="_blank"
          >
            <span className="icon" style={{ color: '#333' }}>
              <i className="fa fa-github"></i>
            </span>
          </a>
          <a
            className="navbar-item is-hidden-desktop"
            href="https://twitter.com/jgthms"
            target="_blank"
          >
            <span className="icon" style={{ color: '#55acee' }}>
              <i className="fa fa-twitter"></i>
            </span>
          </a>
          <div className="navbar-burger burger" data-target="navMenuExample4">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div id="navMenuExample4" className="navbar-menu">
          <div className="navbar-start">
            <a className="navbar-item " href="#">
              Home
            </a>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link  is-active" href="#">
                Docs
              </a>
              <div className="navbar-dropdown ">
                <a className="navbar-item " href="#">
                  Overview
                </a>
                <a className="navbar-item " href="#">
                  Modifiers
                </a>
                <a className="navbar-item " href="#">
                  Grid
                </a>
                <a className="navbar-item " href="#">
                  Form
                </a>
                <a className="navbar-item " href="#">
                  Elements
                </a>
                <a className="navbar-item is-active" href="#">
                  Components
                </a>
                <a className="navbar-item " href="#">
                  Layout
                </a>
                <hr className="navbar-divider" />
                <div className="navbar-item">
                  <div>
                    version
                    <p className="has-text-info is-size-6-desktop">0.4.3</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link " href="#blog/">
                Blog
              </a>
              <div
                id="blogDropdown"
                className="navbar-dropdown "
                data-style="width: 18rem;"
              >
                <a
                  className="navbar-item"
                  href="/2017/03/10/new-field-element/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">10 Mar 2017</small>
                    </p>
                    <p>New field element (for better controls)</p>
                  </div>
                </a>
                <a
                  className="navbar-item"
                  href="/2016/04/11/metro-ui-css-grid-with-bulma-tiles/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">11 Apr 2016</small>
                    </p>
                    <p>Metro UI CSS grid with Bulma tiles</p>
                  </div>
                </a>
                <a
                  className="navbar-item"
                  href="/2016/02/09/blog-launched-new-responsive-columns-new-helpers/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">09 Feb 2016</small>
                    </p>
                    <p>Blog launched, new responsive columns, new helpers</p>
                  </div>
                </a>
                <a className="navbar-item" href="#blog/">
                  More posts
                </a>
                <hr className="navbar-divider" />
                <div className="navbar-item">
                  <div className="navbar-content">
                    <div className="level is-mobile">
                      <div className="level-left">
                        <div className="level-item">
                          <strong>Stay up to date!</strong>
                        </div>
                      </div>
                      <div className="level-right">
                        <div className="level-item">
                          <a
                            className="button is-rss is-small"
                            href="#atom.xml"
                          >
                            <span className="icon is-small">
                              <i className="fa fa-rss"></i>
                            </span>
                            <span>Subscribe</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <div className="navbar-link">More</div>
              <div id="moreDropdown" className="navbar-dropdown ">
                <a className="navbar-item " href="#extensions/">
                  <div className="level is-mobile">
                    <div className="level-left">
                      <div className="level-item">
                        <p>
                          <strong>Extensions</strong>
                          <br />
                          <small>Side projects to enhance Bulma</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="navbar-end">
            <a
              className="navbar-item"
              href="https://github.com/jgthms/bulma"
              target="_blank"
            >
              Github
            </a>
            <a
              className="navbar-item"
              href="https://twitter.com/jgthms"
              target="_blank"
            >
              Twitter
            </a>
            <div className="navbar-item">
              <div className="field is-grouped">
                <p className="control">
                  <a id="twitter" className="button">
                    <span>Tweet</span>
                  </a>
                </p>
                <p className="control">
                  <a
                    className="button is-primary"
                    href="https://github.com/jgthms/bulma/archive/0.4.3.zip"
                  >
                    <span className="icon">
                      <i className="fa fa-download"></i>
                    </span>
                    <span>Download</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <br />

      <nav className="navbar is-success">
        <div className="navbar-brand">
          <a className="navbar-item" href="https://bulma.io">
            <img
              src="https://bulma.io/images/bulma-logo.png"
              alt="Bulma: a modern CSS framework based on Flexbox"
              width="112"
              height="28"
            />
          </a>
          <a
            className="navbar-item is-hidden-desktop"
            href="https://github.com/jgthms/bulma"
            target="_blank"
          >
            <span className="icon" style={{ color: '#333' }}>
              <i className="fa fa-github"></i>
            </span>
          </a>
          <a
            className="navbar-item is-hidden-desktop"
            href="https://twitter.com/jgthms"
            target="_blank"
          >
            <span className="icon" style={{ color: '#55acee' }}>
              <i className="fa fa-twitter"></i>
            </span>
          </a>
          <div className="navbar-burger burger" data-target="navMenuExample5">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div id="navMenuExample5" className="navbar-menu">
          <div className="navbar-start">
            <a className="navbar-item " href="#">
              Home
            </a>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link  is-active" href="#">
                Docs
              </a>
              <div className="navbar-dropdown ">
                <a className="navbar-item " href="#">
                  Overview
                </a>
                <a className="navbar-item " href="#">
                  Modifiers
                </a>
                <a className="navbar-item " href="#">
                  Grid
                </a>
                <a className="navbar-item " href="#">
                  Form
                </a>
                <a className="navbar-item " href="#">
                  Elements
                </a>
                <a className="navbar-item is-active" href="#">
                  Components
                </a>
                <a className="navbar-item " href="#">
                  Layout
                </a>
                <hr className="navbar-divider" />
                <div className="navbar-item">
                  <div>
                    version
                    <p className="has-text-info is-size-6-desktop">0.4.3</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link " href="#blog/">
                Blog
              </a>
              <div
                id="blogDropdown"
                className="navbar-dropdown "
                data-style="width: 18rem;"
              >
                <a
                  className="navbar-item"
                  href="/2017/03/10/new-field-element/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">10 Mar 2017</small>
                    </p>
                    <p>New field element (for better controls)</p>
                  </div>
                </a>
                <a
                  className="navbar-item"
                  href="/2016/04/11/metro-ui-css-grid-with-bulma-tiles/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">11 Apr 2016</small>
                    </p>
                    <p>Metro UI CSS grid with Bulma tiles</p>
                  </div>
                </a>
                <a
                  className="navbar-item"
                  href="/2016/02/09/blog-launched-new-responsive-columns-new-helpers/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">09 Feb 2016</small>
                    </p>
                    <p>Blog launched, new responsive columns, new helpers</p>
                  </div>
                </a>
                <a className="navbar-item" href="#blog/">
                  More posts
                </a>
                <hr className="navbar-divider" />
                <div className="navbar-item">
                  <div className="navbar-content">
                    <div className="level is-mobile">
                      <div className="level-left">
                        <div className="level-item">
                          <strong>Stay up to date!</strong>
                        </div>
                      </div>
                      <div className="level-right">
                        <div className="level-item">
                          <a
                            className="button is-rss is-small"
                            href="#atom.xml"
                          >
                            <span className="icon is-small">
                              <i className="fa fa-rss"></i>
                            </span>
                            <span>Subscribe</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <div className="navbar-link">More</div>
              <div id="moreDropdown" className="navbar-dropdown ">
                <a className="navbar-item " href="#extensions/">
                  <div className="level is-mobile">
                    <div className="level-left">
                      <div className="level-item">
                        <p>
                          <strong>Extensions</strong>
                          <br />
                          <small>Side projects to enhance Bulma</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="navbar-end">
            <a
              className="navbar-item"
              href="https://github.com/jgthms/bulma"
              target="_blank"
            >
              Github
            </a>
            <a
              className="navbar-item"
              href="https://twitter.com/jgthms"
              target="_blank"
            >
              Twitter
            </a>
            <div className="navbar-item">
              <div className="field is-grouped">
                <p className="control">
                  <a id="twitter" className="button">
                    <span>Tweet</span>
                  </a>
                </p>
                <p className="control">
                  <a
                    className="button is-primary"
                    href="https://github.com/jgthms/bulma/archive/0.4.3.zip"
                  >
                    <span className="icon">
                      <i className="fa fa-download"></i>
                    </span>
                    <span>Download</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <br />

      <nav className="navbar is-warning">
        <div className="navbar-brand">
          <a className="navbar-item" href="https://bulma.io">
            <img
              src="https://bulma.io/images/bulma-logo.png"
              alt="Bulma: a modern CSS framework based on Flexbox"
              width="112"
              height="28"
            />
          </a>
          <a
            className="navbar-item is-hidden-desktop"
            href="https://github.com/jgthms/bulma"
            target="_blank"
          >
            <span className="icon" style={{ color: '#333' }}>
              <i className="fa fa-github"></i>
            </span>
          </a>
          <a
            className="navbar-item is-hidden-desktop"
            href="https://twitter.com/jgthms"
            target="_blank"
          >
            <span className="icon" style={{ color: '#55acee' }}>
              <i className="fa fa-twitter"></i>
            </span>
          </a>
          <div className="navbar-burger burger" data-target="navMenuExample6">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div id="navMenuExample6" className="navbar-menu">
          <div className="navbar-start">
            <a className="navbar-item " href="#">
              Home
            </a>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link  is-active" href="#">
                Docs
              </a>
              <div className="navbar-dropdown ">
                <a className="navbar-item " href="#">
                  Overview
                </a>
                <a className="navbar-item " href="#">
                  Modifiers
                </a>
                <a className="navbar-item " href="#">
                  Grid
                </a>
                <a className="navbar-item " href="#">
                  Form
                </a>
                <a className="navbar-item " href="#">
                  Elements
                </a>
                <a className="navbar-item is-active" href="#">
                  Components
                </a>
                <a className="navbar-item " href="#">
                  Layout
                </a>
                <hr className="navbar-divider" />
                <div className="navbar-item">
                  <div>
                    version
                    <p className="has-text-info is-size-6-desktop">0.4.3</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link " href="#blog/">
                Blog
              </a>
              <div
                id="blogDropdown"
                className="navbar-dropdown "
                data-style="width: 18rem;"
              >
                <a
                  className="navbar-item"
                  href="/2017/03/10/new-field-element/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">10 Mar 2017</small>
                    </p>
                    <p>New field element (for better controls)</p>
                  </div>
                </a>
                <a
                  className="navbar-item"
                  href="/2016/04/11/metro-ui-css-grid-with-bulma-tiles/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">11 Apr 2016</small>
                    </p>
                    <p>Metro UI CSS grid with Bulma tiles</p>
                  </div>
                </a>
                <a
                  className="navbar-item"
                  href="/2016/02/09/blog-launched-new-responsive-columns-new-helpers/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">09 Feb 2016</small>
                    </p>
                    <p>Blog launched, new responsive columns, new helpers</p>
                  </div>
                </a>
                <a className="navbar-item" href="#blog/">
                  More posts
                </a>
                <hr className="navbar-divider" />
                <div className="navbar-item">
                  <div className="navbar-content">
                    <div className="level is-mobile">
                      <div className="level-left">
                        <div className="level-item">
                          <strong>Stay up to date!</strong>
                        </div>
                      </div>
                      <div className="level-right">
                        <div className="level-item">
                          <a
                            className="button is-rss is-small"
                            href="#atom.xml"
                          >
                            <span className="icon is-small">
                              <i className="fa fa-rss"></i>
                            </span>
                            <span>Subscribe</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <div className="navbar-link">More</div>
              <div id="moreDropdown" className="navbar-dropdown ">
                <a className="navbar-item " href="#extensions/">
                  <div className="level is-mobile">
                    <div className="level-left">
                      <div className="level-item">
                        <p>
                          <strong>Extensions</strong>
                          <br />
                          <small>Side projects to enhance Bulma</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="navbar-end">
            <a
              className="navbar-item"
              href="https://github.com/jgthms/bulma"
              target="_blank"
            >
              Github
            </a>
            <a
              className="navbar-item"
              href="https://twitter.com/jgthms"
              target="_blank"
            >
              Twitter
            </a>
            <div className="navbar-item">
              <div className="field is-grouped">
                <p className="control">
                  <a id="twitter" className="button">
                    <span>Tweet</span>
                  </a>
                </p>
                <p className="control">
                  <a
                    className="button is-primary"
                    href="https://github.com/jgthms/bulma/archive/0.4.3.zip"
                  >
                    <span className="icon">
                      <i className="fa fa-download"></i>
                    </span>
                    <span>Download</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <br />

      <nav className="navbar is-danger">
        <div className="navbar-brand">
          <a className="navbar-item" href="https://bulma.io">
            <img
              src="https://bulma.io/images/bulma-logo.png"
              alt="Bulma: a modern CSS framework based on Flexbox"
              width="112"
              height="28"
            />
          </a>
          <a
            className="navbar-item is-hidden-desktop"
            href="https://github.com/jgthms/bulma"
            target="_blank"
          >
            <span className="icon" style={{ color: '#333' }}>
              <i className="fa fa-github"></i>
            </span>
          </a>
          <a
            className="navbar-item is-hidden-desktop"
            href="https://twitter.com/jgthms"
            target="_blank"
          >
            <span className="icon" style={{ color: '#55acee' }}>
              <i className="fa fa-twitter"></i>
            </span>
          </a>
          <div className="navbar-burger burger" data-target="navMenuExample7">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div id="navMenuExample7" className="navbar-menu">
          <div className="navbar-start">
            <a className="navbar-item " href="#">
              Home
            </a>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link  is-active" href="#">
                Docs
              </a>
              <div className="navbar-dropdown ">
                <a className="navbar-item " href="#">
                  Overview
                </a>
                <a className="navbar-item " href="#">
                  Modifiers
                </a>
                <a className="navbar-item " href="#">
                  Grid
                </a>
                <a className="navbar-item " href="#">
                  Form
                </a>
                <a className="navbar-item " href="#">
                  Elements
                </a>
                <a className="navbar-item is-active" href="#">
                  Components
                </a>
                <a className="navbar-item " href="#">
                  Layout
                </a>
                <hr className="navbar-divider" />
                <div className="navbar-item">
                  <div>
                    version
                    <p className="has-text-info is-size-6-desktop">0.4.3</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link " href="#blog/">
                Blog
              </a>
              <div
                id="blogDropdown"
                className="navbar-dropdown "
                data-style="width: 18rem;"
              >
                <a
                  className="navbar-item"
                  href="/2017/03/10/new-field-element/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">10 Mar 2017</small>
                    </p>
                    <p>New field element (for better controls)</p>
                  </div>
                </a>
                <a
                  className="navbar-item"
                  href="/2016/04/11/metro-ui-css-grid-with-bulma-tiles/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">11 Apr 2016</small>
                    </p>
                    <p>Metro UI CSS grid with Bulma tiles</p>
                  </div>
                </a>
                <a
                  className="navbar-item"
                  href="/2016/02/09/blog-launched-new-responsive-columns-new-helpers/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">09 Feb 2016</small>
                    </p>
                    <p>Blog launched, new responsive columns, new helpers</p>
                  </div>
                </a>
                <a className="navbar-item" href="#blog/">
                  More posts
                </a>
                <hr className="navbar-divider" />
                <div className="navbar-item">
                  <div className="navbar-content">
                    <div className="level is-mobile">
                      <div className="level-left">
                        <div className="level-item">
                          <strong>Stay up to date!</strong>
                        </div>
                      </div>
                      <div className="level-right">
                        <div className="level-item">
                          <a
                            className="button is-rss is-small"
                            href="#atom.xml"
                          >
                            <span className="icon is-small">
                              <i className="fa fa-rss"></i>
                            </span>
                            <span>Subscribe</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <div className="navbar-link">More</div>
              <div id="moreDropdown" className="navbar-dropdown ">
                <a className="navbar-item " href="#extensions/">
                  <div className="level is-mobile">
                    <div className="level-left">
                      <div className="level-item">
                        <p>
                          <strong>Extensions</strong>
                          <br />
                          <small>Side projects to enhance Bulma</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="navbar-end">
            <a
              className="navbar-item"
              href="https://github.com/jgthms/bulma"
              target="_blank"
            >
              Github
            </a>
            <a
              className="navbar-item"
              href="https://twitter.com/jgthms"
              target="_blank"
            >
              Twitter
            </a>
            <div className="navbar-item">
              <div className="field is-grouped">
                <p className="control">
                  <a id="twitter" className="button">
                    <span>Tweet</span>
                  </a>
                </p>
                <p className="control">
                  <a
                    className="button is-primary"
                    href="https://github.com/jgthms/bulma/archive/0.4.3.zip"
                  >
                    <span className="icon">
                      <i className="fa fa-download"></i>
                    </span>
                    <span>Download</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <br />

      <nav className="navbar is-white">
        <div className="navbar-brand">
          <a className="navbar-item" href="https://bulma.io">
            <img
              src="https://bulma.io/images/bulma-logo.png"
              alt="Bulma: a modern CSS framework based on Flexbox"
              width="112"
              height="28"
            />
          </a>
          <a
            className="navbar-item is-hidden-desktop"
            href="https://github.com/jgthms/bulma"
            target="_blank"
          >
            <span className="icon" style={{ color: '#333' }}>
              <i className="fa fa-github"></i>
            </span>
          </a>
          <a
            className="navbar-item is-hidden-desktop"
            href="https://twitter.com/jgthms"
            target="_blank"
          >
            <span className="icon" style={{ color: '#55acee' }}>
              <i className="fa fa-twitter"></i>
            </span>
          </a>
          <div className="navbar-burger burger" data-target="navMenuExample8">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div id="navMenuExample8" className="navbar-menu">
          <div className="navbar-start">
            <a className="navbar-item " href="#">
              Home
            </a>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link  is-active" href="#">
                Docs
              </a>
              <div className="navbar-dropdown ">
                <a className="navbar-item " href="#">
                  Overview
                </a>
                <a className="navbar-item " href="#">
                  Modifiers
                </a>
                <a className="navbar-item " href="#">
                  Grid
                </a>
                <a className="navbar-item " href="#">
                  Form
                </a>
                <a className="navbar-item " href="#">
                  Elements
                </a>
                <a className="navbar-item is-active" href="#">
                  Components
                </a>
                <a className="navbar-item " href="#">
                  Layout
                </a>
                <hr className="navbar-divider" />
                <div className="navbar-item">
                  <div>
                    version
                    <p className="has-text-info is-size-6-desktop">0.4.3</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link " href="#blog/">
                Blog
              </a>
              <div
                id="blogDropdown"
                className="navbar-dropdown "
                data-style="width: 18rem;"
              >
                <a
                  className="navbar-item"
                  href="/2017/03/10/new-field-element/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">10 Mar 2017</small>
                    </p>
                    <p>New field element (for better controls)</p>
                  </div>
                </a>
                <a
                  className="navbar-item"
                  href="/2016/04/11/metro-ui-css-grid-with-bulma-tiles/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">11 Apr 2016</small>
                    </p>
                    <p>Metro UI CSS grid with Bulma tiles</p>
                  </div>
                </a>
                <a
                  className="navbar-item"
                  href="/2016/02/09/blog-launched-new-responsive-columns-new-helpers/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">09 Feb 2016</small>
                    </p>
                    <p>Blog launched, new responsive columns, new helpers</p>
                  </div>
                </a>
                <a className="navbar-item" href="#blog/">
                  More posts
                </a>
                <hr className="navbar-divider" />
                <div className="navbar-item">
                  <div className="navbar-content">
                    <div className="level is-mobile">
                      <div className="level-left">
                        <div className="level-item">
                          <strong>Stay up to date!</strong>
                        </div>
                      </div>
                      <div className="level-right">
                        <div className="level-item">
                          <a
                            className="button is-rss is-small"
                            href="#atom.xml"
                          >
                            <span className="icon is-small">
                              <i className="fa fa-rss"></i>
                            </span>
                            <span>Subscribe</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <div className="navbar-link">More</div>
              <div id="moreDropdown" className="navbar-dropdown ">
                <a className="navbar-item " href="#extensions/">
                  <div className="level is-mobile">
                    <div className="level-left">
                      <div className="level-item">
                        <p>
                          <strong>Extensions</strong>
                          <br />
                          <small>Side projects to enhance Bulma</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="navbar-end">
            <a
              className="navbar-item"
              href="https://github.com/jgthms/bulma"
              target="_blank"
            >
              Github
            </a>
            <a
              className="navbar-item"
              href="https://twitter.com/jgthms"
              target="_blank"
            >
              Twitter
            </a>
            <div className="navbar-item">
              <div className="field is-grouped">
                <p className="control">
                  <a id="twitter" className="button">
                    <span>Tweet</span>
                  </a>
                </p>
                <p className="control">
                  <a
                    className="button is-primary"
                    href="https://github.com/jgthms/bulma/archive/0.4.3.zip"
                  >
                    <span className="icon">
                      <i className="fa fa-download"></i>
                    </span>
                    <span>Download</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <br />

      <nav className="navbar is-black">
        <div className="navbar-brand">
          <a className="navbar-item" href="https://bulma.io">
            <img
              src="https://bulma.io/images/bulma-logo.png"
              alt="Bulma: a modern CSS framework based on Flexbox"
              width="112"
              height="28"
            />
          </a>
          <a
            className="navbar-item is-hidden-desktop"
            href="https://github.com/jgthms/bulma"
            target="_blank"
          >
            <span className="icon" style={{ color: '#333' }}>
              <i className="fa fa-github"></i>
            </span>
          </a>
          <a
            className="navbar-item is-hidden-desktop"
            href="https://twitter.com/jgthms"
            target="_blank"
          >
            <span className="icon" style={{ color: '#55acee' }}>
              <i className="fa fa-twitter"></i>
            </span>
          </a>
          <div className="navbar-burger burger" data-target="navMenuExample9">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div id="navMenuExample9" className="navbar-menu">
          <div className="navbar-start">
            <a className="navbar-item " href="#">
              Home
            </a>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link  is-active" href="#">
                Docs
              </a>
              <div className="navbar-dropdown ">
                <a className="navbar-item " href="#">
                  Overview
                </a>
                <a className="navbar-item " href="#">
                  Modifiers
                </a>
                <a className="navbar-item " href="#">
                  Grid
                </a>
                <a className="navbar-item " href="#">
                  Form
                </a>
                <a className="navbar-item " href="#">
                  Elements
                </a>
                <a className="navbar-item is-active" href="#">
                  Components
                </a>
                <a className="navbar-item " href="#">
                  Layout
                </a>
                <hr className="navbar-divider" />
                <div className="navbar-item">
                  <div>
                    version
                    <p className="has-text-info is-size-6-desktop">0.4.3</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link " href="#blog/">
                Blog
              </a>
              <div
                id="blogDropdown"
                className="navbar-dropdown "
                data-style="width: 18rem;"
              >
                <a
                  className="navbar-item"
                  href="/2017/03/10/new-field-element/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">10 Mar 2017</small>
                    </p>
                    <p>New field element (for better controls)</p>
                  </div>
                </a>
                <a
                  className="navbar-item"
                  href="/2016/04/11/metro-ui-css-grid-with-bulma-tiles/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">11 Apr 2016</small>
                    </p>
                    <p>Metro UI CSS grid with Bulma tiles</p>
                  </div>
                </a>
                <a
                  className="navbar-item"
                  href="/2016/02/09/blog-launched-new-responsive-columns-new-helpers/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">09 Feb 2016</small>
                    </p>
                    <p>Blog launched, new responsive columns, new helpers</p>
                  </div>
                </a>
                <a className="navbar-item" href="#blog/">
                  More posts
                </a>
                <hr className="navbar-divider" />
                <div className="navbar-item">
                  <div className="navbar-content">
                    <div className="level is-mobile">
                      <div className="level-left">
                        <div className="level-item">
                          <strong>Stay up to date!</strong>
                        </div>
                      </div>
                      <div className="level-right">
                        <div className="level-item">
                          <a
                            className="button is-rss is-small"
                            href="#atom.xml"
                          >
                            <span className="icon is-small">
                              <i className="fa fa-rss"></i>
                            </span>
                            <span>Subscribe</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <div className="navbar-link">More</div>
              <div id="moreDropdown" className="navbar-dropdown ">
                <a className="navbar-item " href="#extensions/">
                  <div className="level is-mobile">
                    <div className="level-left">
                      <div className="level-item">
                        <p>
                          <strong>Extensions</strong>
                          <br />
                          <small>Side projects to enhance Bulma</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="navbar-end">
            <a
              className="navbar-item"
              href="https://github.com/jgthms/bulma"
              target="_blank"
            >
              Github
            </a>
            <a
              className="navbar-item"
              href="https://twitter.com/jgthms"
              target="_blank"
            >
              Twitter
            </a>
            <div className="navbar-item">
              <div className="field is-grouped">
                <p className="control">
                  <a id="twitter" className="button">
                    <span>Tweet</span>
                  </a>
                </p>
                <p className="control">
                  <a
                    className="button is-primary"
                    href="https://github.com/jgthms/bulma/archive/0.4.3.zip"
                  >
                    <span className="icon">
                      <i className="fa fa-download"></i>
                    </span>
                    <span>Download</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <br />

      <nav className="navbar is-light">
        <div className="navbar-brand">
          <a className="navbar-item" href="https://bulma.io">
            <img
              src="https://bulma.io/images/bulma-logo.png"
              alt="Bulma: a modern CSS framework based on Flexbox"
              width="112"
              height="28"
            />
          </a>
          <a
            className="navbar-item is-hidden-desktop"
            href="https://github.com/jgthms/bulma"
            target="_blank"
          >
            <span className="icon" style={{ color: '#333' }}>
              <i className="fa fa-github"></i>
            </span>
          </a>
          <a
            className="navbar-item is-hidden-desktop"
            href="https://twitter.com/jgthms"
            target="_blank"
          >
            <span className="icon" style={{ color: '#55acee' }}>
              <i className="fa fa-twitter"></i>
            </span>
          </a>
          <div className="navbar-burger burger" data-target="navMenuExample10">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div id="navMenuExample10" className="navbar-menu">
          <div className="navbar-start">
            <a className="navbar-item " href="#">
              Home
            </a>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link  is-active" href="#">
                Docs
              </a>
              <div className="navbar-dropdown ">
                <a className="navbar-item " href="#">
                  Overview
                </a>
                <a className="navbar-item " href="#">
                  Modifiers
                </a>
                <a className="navbar-item " href="#">
                  Grid
                </a>
                <a className="navbar-item " href="#">
                  Form
                </a>
                <a className="navbar-item " href="#">
                  Elements
                </a>
                <a className="navbar-item is-active" href="#">
                  Components
                </a>
                <a className="navbar-item " href="#">
                  Layout
                </a>
                <hr className="navbar-divider" />
                <div className="navbar-item">
                  <div>
                    version
                    <p className="has-text-info is-size-6-desktop">0.4.3</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link " href="#blog/">
                Blog
              </a>
              <div
                id="blogDropdown"
                className="navbar-dropdown "
                data-style="width: 18rem;"
              >
                <a
                  className="navbar-item"
                  href="/2017/03/10/new-field-element/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">10 Mar 2017</small>
                    </p>
                    <p>New field element (for better controls)</p>
                  </div>
                </a>
                <a
                  className="navbar-item"
                  href="/2016/04/11/metro-ui-css-grid-with-bulma-tiles/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">11 Apr 2016</small>
                    </p>
                    <p>Metro UI CSS grid with Bulma tiles</p>
                  </div>
                </a>
                <a
                  className="navbar-item"
                  href="/2016/02/09/blog-launched-new-responsive-columns-new-helpers/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">09 Feb 2016</small>
                    </p>
                    <p>Blog launched, new responsive columns, new helpers</p>
                  </div>
                </a>
                <a className="navbar-item" href="#blog/">
                  More posts
                </a>
                <hr className="navbar-divider" />
                <div className="navbar-item">
                  <div className="navbar-content">
                    <div className="level is-mobile">
                      <div className="level-left">
                        <div className="level-item">
                          <strong>Stay up to date!</strong>
                        </div>
                      </div>
                      <div className="level-right">
                        <div className="level-item">
                          <a
                            className="button is-rss is-small"
                            href="#atom.xml"
                          >
                            <span className="icon is-small">
                              <i className="fa fa-rss"></i>
                            </span>
                            <span>Subscribe</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <div className="navbar-link">More</div>
              <div id="moreDropdown" className="navbar-dropdown ">
                <a className="navbar-item " href="#extensions/">
                  <div className="level is-mobile">
                    <div className="level-left">
                      <div className="level-item">
                        <p>
                          <strong>Extensions</strong>
                          <br />
                          <small>Side projects to enhance Bulma</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="navbar-end">
            <a
              className="navbar-item"
              href="https://github.com/jgthms/bulma"
              target="_blank"
            >
              Github
            </a>
            <a
              className="navbar-item"
              href="https://twitter.com/jgthms"
              target="_blank"
            >
              Twitter
            </a>
            <div className="navbar-item">
              <div className="field is-grouped">
                <p className="control">
                  <a id="twitter" className="button">
                    <span>Tweet</span>
                  </a>
                </p>
                <p className="control">
                  <a
                    className="button is-primary"
                    href="https://github.com/jgthms/bulma/archive/0.4.3.zip"
                  >
                    <span className="icon">
                      <i className="fa fa-download"></i>
                    </span>
                    <span>Download</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <br />

      <nav className="navbar is-dark">
        <div className="navbar-brand">
          <a className="navbar-item" href="https://bulma.io">
            <img
              src="https://bulma.io/images/bulma-logo.png"
              alt="Bulma: a modern CSS framework based on Flexbox"
              width="112"
              height="28"
            />
          </a>
          <a
            className="navbar-item is-hidden-desktop"
            href="https://github.com/jgthms/bulma"
            target="_blank"
          >
            <span className="icon" style={{ color: '#333' }}>
              <i className="fa fa-github"></i>
            </span>
          </a>
          <a
            className="navbar-item is-hidden-desktop"
            href="https://twitter.com/jgthms"
            target="_blank"
          >
            <span className="icon" style={{ color: '#55acee' }}>
              <i className="fa fa-twitter"></i>
            </span>
          </a>
          <div className="navbar-burger burger" data-target="navMenuExample11">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div id="navMenuExample11" className="navbar-menu">
          <div className="navbar-start">
            <a className="navbar-item " href="#">
              Home
            </a>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link  is-active" href="#">
                Docs
              </a>
              <div className="navbar-dropdown ">
                <a className="navbar-item " href="#">
                  Overview
                </a>
                <a className="navbar-item " href="#">
                  Modifiers
                </a>
                <a className="navbar-item " href="#">
                  Grid
                </a>
                <a className="navbar-item " href="#">
                  Form
                </a>
                <a className="navbar-item " href="#">
                  Elements
                </a>
                <a className="navbar-item is-active" href="#">
                  Components
                </a>
                <a className="navbar-item " href="#">
                  Layout
                </a>
                <hr className="navbar-divider" />
                <div className="navbar-item">
                  <div>
                    version
                    <p className="has-text-info is-size-6-desktop">0.4.3</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link " href="#blog/">
                Blog
              </a>
              <div
                id="blogDropdown"
                className="navbar-dropdown "
                data-style="width: 18rem;"
              >
                <a
                  className="navbar-item"
                  href="/2017/03/10/new-field-element/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">10 Mar 2017</small>
                    </p>
                    <p>New field element (for better controls)</p>
                  </div>
                </a>
                <a
                  className="navbar-item"
                  href="/2016/04/11/metro-ui-css-grid-with-bulma-tiles/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">11 Apr 2016</small>
                    </p>
                    <p>Metro UI CSS grid with Bulma tiles</p>
                  </div>
                </a>
                <a
                  className="navbar-item"
                  href="/2016/02/09/blog-launched-new-responsive-columns-new-helpers/"
                >
                  <div className="navbar-content">
                    <p>
                      <small className="has-text-info">09 Feb 2016</small>
                    </p>
                    <p>Blog launched, new responsive columns, new helpers</p>
                  </div>
                </a>
                <a className="navbar-item" href="#blog/">
                  More posts
                </a>
                <hr className="navbar-divider" />
                <div className="navbar-item">
                  <div className="navbar-content">
                    <div className="level is-mobile">
                      <div className="level-left">
                        <div className="level-item">
                          <strong>Stay up to date!</strong>
                        </div>
                      </div>
                      <div className="level-right">
                        <div className="level-item">
                          <a
                            className="button is-rss is-small"
                            href="#atom.xml"
                          >
                            <span className="icon is-small">
                              <i className="fa fa-rss"></i>
                            </span>
                            <span>Subscribe</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <div className="navbar-link">More</div>
              <div id="moreDropdown" className="navbar-dropdown ">
                <a className="navbar-item " href="#extensions/">
                  <div className="level is-mobile">
                    <div className="level-left">
                      <div className="level-item">
                        <p>
                          <strong>Extensions</strong>
                          <br />
                          <small>Side projects to enhance Bulma</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="navbar-end">
            <a
              className="navbar-item"
              href="https://github.com/jgthms/bulma"
              target="_blank"
            >
              Github
            </a>
            <a
              className="navbar-item"
              href="https://twitter.com/jgthms"
              target="_blank"
            >
              Twitter
            </a>
            <div className="navbar-item">
              <div className="field is-grouped">
                <p className="control">
                  <a id="twitter" className="button">
                    <span>Tweet</span>
                  </a>
                </p>
                <p className="control">
                  <a
                    className="button is-primary"
                    href="https://github.com/jgthms/bulma/archive/0.4.3.zip"
                  >
                    <span className="icon">
                      <i className="fa fa-download"></i>
                    </span>
                    <span>Download</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <br />

      <h2 className="subtitle">Transparent</h2>
      <nav className="navbar is-transparent">
        <div className="navbar-brand">
          <a className="navbar-item" href="https://bulma.io">
            <img
              src="https://bulma.io/images/bulma-logo.png"
              alt="Bulma: a modern CSS framework based on Flexbox"
              width="112"
              height="28"
            />
          </a>
          <a
            className="navbar-item is-hidden-desktop"
            href="https://github.com/jgthms/bulma"
            target="_blank"
          >
            <span className="icon" style={{ color: '#333' }}>
              <i className="fa fa-github"></i>
            </span>
          </a>
          <a
            className="navbar-item is-hidden-desktop"
            href="https://twitter.com/jgthms"
            target="_blank"
          >
            <span className="icon" style={{ color: '#55acee' }}>
              <i className="fa fa-twitter"></i>
            </span>
          </a>
          <div className="navbar-burger burger" data-target="navMenuExamplet">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div id="navMenuExamplet" className="navbar-menu">
          <div className="navbar-start">
            <a className="navbar-item " href="#">
              Home
            </a>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link  is-active" href="#">
                Docs
              </a>
              <div className="navbar-dropdown is-boxed">
                <a className="navbar-item " href="#">
                  Overview
                </a>
                <a className="navbar-item " href="#">
                  Discover
                </a>
                <hr className="navbar-divider" />
                <div className="navbar-item">
                  <div>
                    version
                    <p className="has-text-info is-size-6-desktop">0.4.3</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="navbar-end">
            <a
              className="navbar-item"
              href="https://github.com/jgthms/bulma"
              target="_blank"
            >
              Github
            </a>
            <div className="navbar-item">
              <div className="field is-grouped">
                <p className="control">
                  <a id="twitter" className="button">
                    <span>Tweet</span>
                  </a>
                </p>
                <p className="control">
                  <a
                    className="button is-primary"
                    href="https://github.com/jgthms/bulma/archive/0.4.3.zip"
                  >
                    <span className="icon">
                      <i className="fa fa-download"></i>
                    </span>
                    <span>Download</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </section>
  </div>
)

const ThemePage = () => (
  <Layout>
    <BulmaThemeTest />
  </Layout>
)

export default ThemePage
